export const Loader = ({
  color = "#4f46e5" // indigo-600
}: {
  color?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: "auto",
      background: "none",
      WebkitAnimationPlayState: "running",
      animationPlayState: "running",
      WebkitAnimationDelay: "0s",
      animationDelay: "0s"
    }}
    width="200"
    height="200"
    display="block"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
    data-cy="loader"
  >
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
    >
      <animate
        attributeName="opacity"
        begin="-0.9166666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.8333333333333334s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.75s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.6666666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.5833333333333334s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.5s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.4166666666666667s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.3333333333333333s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.25s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.16666666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="-0.08333333333333333s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
    <rect
      width="6"
      height="12"
      x="47"
      y="24"
      fill={color}
      rx="3"
      ry="6"
      style={{
        WebkitAnimationPlayState: "running",
        animationPlayState: "running",
        WebkitAnimationDelay: "0s",
        animationDelay: "0s"
      }}
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        begin="0s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </rect>
  </svg>
);
